import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    token:''
  },
  getters: {
  },
  mutations: {
    SET_TOKEN:(state,token)=>{
        state.token = token
        localStorage.setItem('client_token',token)
    },
    DEL_TOKEN:(state)=>{
      state.token = ''
      localStorage.removeItem('client_token')
    },
  },
  actions: {
  },
  modules: {
  }
})
export default store
