import request from '@/api/request'

/** 密码登录 **/
/*export function Login(data) {
    return request({
        url: '/sso/login',
        method: 'post',
        data: data
    })
}*/
/** 短信登录 **/
export function Login(data) {
    return request({
        url: '/sso/sms/login',
        method: 'post',
        data: data
    })
}
/** 用户发送验证码 **/
export function smsCode(data) {
    return request({
        url: '/sso/smsCode/send',
        method: 'get',
        params: data
    })
}
/** 用户发送验证码 **/
export function client(data) {
    return request({
        url: '/sso/client/list',
        method: 'get',
        params: data
    })
}
/** 用户退出登录 **/
export function logout(data) {
  return request({
    url: '/sso/logout',
    method: 'get',
    params: data
  })
}
