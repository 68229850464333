import axios from 'axios'
import qs from 'qs'
import {
    Message,
    MessageBox
} from 'element-ui'
import el from "element-ui/src/locale/lang/el";
const service = axios.create({
    baseURL: 'https://login.zwzx.com',
    timeout: 10000
})
import store from '../store'
import router from "@/router";
service.interceptors.request.use(
    config => {
        if(config.type == undefined){
            config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
            config.data = qs.stringify(config.data, { arrayFormat: 'indices', allowDots: true })
        }

        // type不传 默认为fromData； 1为JSON
        if (config.type == 1) {
            config.headers['Content-Type'] = 'application/json;charset=UTF-8'
        }
        config.headers['Cache-Control'] = 'no-cache'
        // config.url= config.url +"&token="+ (store.getters.token||'') +"&selfId= "+ (store.getters.selfId||'');

        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    })

service.interceptors.response.use(
    response => {
        const res = response.data
        if (res.status !== 200) {
            if ( res.status === 401) {
                MessageBox.confirm('您的登录失效，请重新登录', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    showClose: false,
                    showCancelButton: false,
                    closeOnClickModal: false,
                    closeOnPressEscape: false,
                    type: 'warning'
                }).then(() => {
                    store.commit('DEL_TOKEN')
                    router.push('/Login')
                })
            }
            else {
                Message({
                    message: res.message || 'Error',
                    type: 'error',
                    duration: 5 * 1000
                })
                return Promise.reject(res)
            }

        } else {
            return res
        }
    },
    error => {
        Message({
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)
export default service
