import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login/index'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/client'
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login
  },
  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  },
  {
    path: '/client',
    component: () => import('@/views/client/client'),
    hidden: true
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
]

const router = new VueRouter({
  routes
})

store.commit('SET_TOKEN',localStorage.getItem('client_token'))
router.beforeEach((to, from, next)=>{
  const token = store.state.token
  console.log(token,111)
  if(token){
    if (to.path === '/Login') {
      next({path:'/client'})
    }else{
      next()
    }
  }else{
    console.log(token,from.path,to.path)
    if (to.path === '/Login') {
      next()
    }else{
      next({path:'/Login'})
    }
  }


})

export default router
